<template>
  <div
    class="header"
    style="background-color: white; padding-top: 0px"
    :key="filtersUpdater"
  >
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("Projects") }}</h3>
      <AddNewButton />
    </v-row>
    <div class="tab-con">
      <div
        @click="tabViewToActive"
        class="tab"
        :class="
          activeProjectsTab === 'projects'
            ? 'content-small-semibold tab-selected'
            : 'content-small'
        "
      >
        {{ $t("ActiveProjects") }}
      </div>
      <div
        @click="tabViewToArchives"
        class="tab"
        :class="
          activeProjectsTab === 'archives'
            ? 'content-small-semibold tab-selected'
            : 'content-small'
        "
      >
        {{ $t("Archive") }}
      </div>
    </div>
        <HeaderToolbar
            style="
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      "
            :module="'projects'"
        >
          <v-row style="justify-content: space-between; width: 100%">
            <div style="display: flex; flex-direction: row; gap: 8px">
              <button
                @click="changeSidepanelStateDeals()"
                name="add-project-button"
                class="outlined-small-button"
                style="background-color: black !important"
          >
            <img
                :src="require('@/assets/images/plusSign.svg')"
                style="
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
                "
                alt=""
                />
                <span
                    style="
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                  brightness(103%) contrast(102%);
              "
                >
                  {{ $t("AddProject") }}
                </span>
              </button>

              <FilterDropdown
            v-if="loaded"
                  :from-dashboard="savedViewId"
                  filter-name="projects"
                  @currentSelectedFilter="updateFilters($event)"
              />

              <img
                  class="point-separator"
                  style="margin-left: 0px; margin-right: 0px"
                  src="../../assets/images/•.svg"
                  alt=""
              />

              <div style="margin-left: 0px">
                <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="search"
                    hide-details
                    :id="'search-bar'"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('Search') + '...'"
                    style="background: white"
                >
                </v-text-field>
              </div>
              <div>
                <DropdownMulti
                  :items="dealTypes"
                  v-model="selectedDeals"
                  :height="'34px'"
                  :font-size="'12px'"
                  :display-key="'name'"
                  :value-key="'value'"
                  :default-value="$t('AllDeals')"
                />
              </div>
          <GroupOrUserSelect v-model="chosenUsers" module="projects" />
            <DropdownMulti
                :items="estateTypes"
                v-model="selectedEstates"
                :display-key="'name'"
                :value-key="'value'"
                :height="'34px'"
                :width="'140px'"
                :font-size="'12px'"
                :default-value="$t('AllEstateTypes')"
            />
            <DropdownMulti
                :items="extraTypes"
                v-model="selectedExtraTypes"
                :display-key="'name'"
                :value-key="'value'"
                :height="'34px'"
                :width="'140px'"
                :font-size="'12px'"
                :default-value="$t('CommercialPurposeOfUse')"
            />
              <div
                  @click="openExtraFilters"
                  style="
              cursor: pointer;
              margin-left: 4px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
            "
              >
                <p style="align-self: center" class="link-small">
                  {{ $t("Filters") }}
                </p>
                <img
                    style="
                width: 16px;
                height: 16px;
                margin-left: 8px;
                cursor: pointer;
                align-self: center;
              "
                    src="../../assets/images/chevron.svg"
                    alt=""
                    v-bind:class="{
                up: filtersOpen,
                down: !filtersOpen,
              }"
                />
              </div>
            </div>
            <span style="display: flex" v-show="activeProjectsTab !== 'archives'">
          <div class="project-radio-view">
            <div
                @click="setActiveProjectsView('GridView')"
                :style="
                activeProjectsView === 'GridView'
                  ? 'background-color: #F4F5F7 !important;'
                  : null
              "
                class="project-radio-view-item content-small view-section"
            >
              <img
                  src="../../assets/images/grid_view2.svg"
                  alt=""
                  class="view-button"
              />
            </div>
            <div
                @click="setActiveProjectsView('ListView')"
                class="project-radio-view-item content-small view-section"
                :style="
                activeProjectsView === 'ListView'
                  ? 'background-color: #F4F5F7 !important;'
                  : null
              "
            >
              <img
                  src="../../assets/images/list_view.svg"
                  alt=""
                  class="view-button"
              />
            </div>
          </div>

          <div
              @click="openProjectSettings()"
              style="
              width: 34px;
              height: 34px;
              border-radius: 8px;
              background: black;
              align-items: center;
              display: flex;
              justify-content: center;
              cursor: pointer;
              margin-left: 8px;
            "
          >
            <img src="../../assets/images/admin_16_16_white.svg" alt=""/>
          </div>
          </span>
      </v-row>

      <AdvancedFilters
          :key="'advanced-filters-' + toolbarUpdater"
          :filter-data="filters"
          :chosen-broker="chosenBrokerId"
          @filtersReset="clearFilters"
          v-show="filtersOpen"
      ></AdvancedFilters>
    </HeaderToolbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddNewButton from "@/components/common/AddNew";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import DropdownMulti from "@/components/common/DropdownMulti";
import AdvancedFilters from "@/components/Deals/AdvancedFilters.vue";

import nanoid from "nanoid";
import FilterDropdown from "@/components/common/FilterDropdown";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";

export default {
  name: "Kliendiregister",
  components: {
    GroupOrUserSelect,
    FilterDropdown,
    AddNewButton,
    HeaderToolbar,
    DropdownMulti,
    AdvancedFilters
    // GroupDropdown,
  },
  props: ["initFilterCondition", "projectSettingsPanelStatus"],
  data() {
    return {
      groups: [
        { name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers" },
      ],
      chosenUsers: null,
      loaded: false,
      savedViewId: null,
      filtersOpen: false,
      view: "Kanban",
      search: "",
      brokerId: "",
      dealtTypes: [],
      dealTypesChosen: [],
      estateTypesChosen: [],
      estateTypesExtrasChosen: [],
      selectedEstates: [],
      selectedExtraTypes: [],
      createdFromDate: "",
      createdToDate: "",
      toolbarUpdater: 0,
      currentActiveFilter: null,
      colleagueUpdater: 0,
      colleaguesPlaceholderId: nanoid(16),
      selectedUser: null,
      chosenBrokerId: null,
      selectedProjectTypes: null,
      filtersUpdater: 0,
      extrasUpdater: 0,
      projectSettingsOpened: false,
      colleagues: [
        { name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers" },
      ],
      projectTypes: [
        { name: this.$t("Sale"), value: "Sale" },
        { name: this.$t("LeaseOut"), value: "LeaseOut" },
      ],
      dealTypes: [
        {name: this.$t("Sale"), value: "Sale"},
        {name: this.$t("LeaseOut"), value: "LeaseOut"},
        {
          name: this.$t("Purchase"),
          value: "Purchase",
        },
        {
          name: this.$t("AquireLease"),
          value: "AquireLease",
        },
        {
          name: this.$t("Referral"),
          value: "Refferal",
        },
      ],
      selectedDeals: [],
      estateTypes: [
        {
          name: this.$t("CommercialSpace"),
          value: "commercial",
        },
        {name: this.$t("Apartment"), value: "apartment"},
        {name: this.$t("House"), value: "house"},
        {name: this.$t("HousePart"), value: "house_part"},
        {name: this.$t("LandPlot"), value: "land"},
        {name: this.$t("SummerHouse"), value: "cottage"},
        {name: this.$t("Garage"), value: "garage"},
      ],
      extraTypes: [
          {
            name: this.$t("LandPlot"),
            value: "LandPlot",
            heading: true
          },
          {
            name: this.$t("ResidentialLand"),
            value: "residentialLand",
          },
          {
            name: this.$t("ResidentialLandApartments"),
            value: "residentialLandApartment",
          },
          {name: this.$t("CommercialLand"), value: "commercialLand"},
          {name: this.$t("ProductionLand"), value: "productionLand"},
          {name: this.$t("IndustrialLand"), value: "industrialLand"},
          {name: this.$t("ForestLand"), value: "forestLand"},
          {name: this.$t("ProfitYieldingLand"), value: "profityieldingLand"},
          {name: this.$t("OtherUse"), value: "otherUse"},
          {name: this.$t("CommercialSpace"), value: "CommercialSpace", heading: true},
          {
            name: this.$t("Office"),
            value: "office",
          },
          {name: this.$t("TradeShop"), value: "tradeshop"},
          {name: this.$t("Accomodation"), value: "accomodation"},
          {name: this.$t("Services"), value: "services"},
          {name: this.$t("WareHouse"), value: "warehouse"},
          {name: this.$t("Production"), value: "production"},
          {
            name: this.$t("WareHouseProduction"),
            value: "warehouseProduction",
          },
          {name: this.$t("FoodServices"), value: "foodServices"},
          {name: this.$t("OtherUsePossible"), value: "otherUsePossible"},
          {name: this.$t("NotDefined"), value: "notDefined"},
        ],
      estateTypesExtras: [],
      commercialExtras: [],
      commercialLabel: {
        name: this.$t("CommercialSpace"),
        value: "sub-label",
      },
      landExtras: [],
      landLabel: {
        name: this.$t("LandPlot"),
        value: "sub-label",
      },
    };
  },
  created() {
    this.getUsers();
    this.getGroups();
    this.checkForViews();
    this.setupExtras();

  },
  mounted() {
    this.filterByConditions(this.initFilterCondition)
  },
  watch: {
    async chosenUsers(value) {
      await this.changeProvisionalFilterCondition({
        filterName: "projects",
        conditionName: "brokerId",
        value: value,
        valueTitle: ""
      });
    },
    search: async function (val) {
      await this.filter({
        filterName: "projects",
        conditionName: "search",
        value: val,
        valueTitle: val,
      });
    },
    async projectsFiltersReset() {
      await this.clearFilters();
    },
    provisionalFiltersUpdater() {
      const conditions = this.provisionalFilters["projects"];
      this.filterByConditions(conditions);
    },
    projectSettingsPanelStatus() {
      this.projectSettingsOpened = false;
    },
    selectedDeals: {
      async handler(newVal) {
        await this.filterByCondition(newVal, "dealTypes");
      },
      deep: true,
    },
    selectedEstates: {
      async handler(newVal) {
        await this.filterByCondition(newVal, "estateTypes");
      },
      deep: true,
    },
    selectedExtraTypes: {
      async handler(newVal) {
        await this.filterByCondition(newVal, "extraTypes");
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "projectsFiltersReset",
      "user",
      "users",
      "provisionalFilters",
      "provisionalFiltersUpdater",
      "filters",
      "activeProjectsView",
      "activeProjectsTab",
      "activeProjectsPreviousView",
    ]),
  },
  methods: {
    ...mapActions([
      "filter",
      "changeSidepanelStateDeals",
      "changeSidepanelStateDeal",
      "filterDeals",
      "changeProvisionalFilterCondition",
      "setProvisionalFilter",
      "setActiveProjectsTab",
      "setActiveProjectsView",
      "setActiveProjectsPreviousView",
      "getGroups",
      "getUsers"
    ]),
    setupExtras() {
      this.landExtras = this.extraTypes["land"].filter(
          (extra) => extra.value !== "placeholder"
      );
      this.commercialExtras = this.extraTypes["commercial"].filter(
          (extra) => extra.value !== "placeholder"
      );

      this.landExtras = [this.landLabel].concat(this.landExtras);

      this.commercialExtras = [this.commercialLabel].concat(
          this.commercialExtras
      );

      this.estateTypesExtras.push(...this.landExtras);
      this.estateTypesExtras.push(...this.commercialExtras);
    },
    setExtras(estateTypes) {
      this.estateTypesExtras = [
        {
          name: this.$t("Purpose"),
          value: "allExtraTypes",
          _id: nanoid(16),
        },
      ];

      if (estateTypes.length === 0) {
        this.estateTypesExtras.push(...this.landExtras);
        this.estateTypesExtras.push(...this.commercialExtras);
        this.extrasUpdater++;
        return;
      }
      for (let estateType of estateTypes) {
        if (estateType === "commercial") {
          this.estateTypesExtras.push(...this.commercialExtras);
        } else if (estateType === "land") {
          this.estateTypesExtras.push(...this.landExtras);
        }
      }

      this.extrasUpdater++;
    },

    openExtraFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
    tabViewToArchives() {
      this.setActiveProjectsPreviousView(this.activeProjectsView);
      this.setActiveProjectsTab("archives");
    },
    tabViewToActive() {
      this.setActiveProjectsTab("projects");
      this.setActiveProjectsView(this.activeProjectsPreviousView);
    },
    openProjectSettings() {
      this.projectSettingsOpened = !this.projectSettingsOpened;
      this.$emit("openProjectSettings", this.projectSettingsOpened);
    },
    checkForViews() {
      this.setActiveProjectsTab("projects");
      if (!this.activeProjectsView) {
        this.setActiveProjectsView("GridView");
      }
    },
    async updateFilters(event) {
      this.currentActiveFilter = event;
      this.brokerId = event.conditions.find(
          (field) => field.name === "brokerId"
      ).value;
      let dealTypes = event.conditions.find(
        (field) => field.name === "dealTypes"
      );
      let estateTypes = event.conditions.find(
        (field) => field.name === "estateTypes"
      );
      let extraTypes = event.conditions.find(
        (field) => field.name === "extraTypes"
      );
      this.selectedDeals = this.dealTypes.filter(type => dealTypes.value.includes(type.value));
      this.selectedEstates = this.estateTypes.filter(type => estateTypes.value.includes(type.value));
      this.selectedExtraTypes = this.extraTypes.filter(type => extraTypes.value.includes(type.value));
      this.filterByConditions(event.conditions);
      this.toolbarUpdater++;
      this.colleagueUpdater++;
    },
    async clearFilters() {
      const some = JSON.parse(
          JSON.stringify(this.filters["projects"][0].conditions)
      );
      this.setProvisionalFilter({
        filterName: "projects",
        conditions: some,
      });
      this.toolbarUpdater++;
      this.colleagueUpdater++;
      // this.refreshToolBar();
    },
    refreshToolBar() {
      const conditions = this.provisionalFilters["projects"];
      this.filterByConditions(conditions);

      if (this.dropdownOpen) {
        this.toolbarUpdater++;
      }
      //this.toolbarUpdater++;
    },
    async getBrokerDeals(selectedItem) {
      let value = [];
      if (selectedItem.users) {
        value = Object.keys(selectedItem.users)
      } else if (selectedItem.value !== "allBrokers" && !selectedItem.users) {
        value.push(selectedItem._id);
      }
      await this.changeProvisionalFilterCondition({
        filterName: "projects",
        conditionName: "brokerId",
        value: selectedItem.value === "allBrokers" ? 0 : value,
        valueTitle:
          selectedItem.value === "allBrokers"
            ? "allBrokers"
            : selectedItem.name,
      });
    },
    async filterByCondition(items, conditionName) {
      let valuesArray = [];
      items.forEach((item) => {
        valuesArray.push(item.value);
      });
      await this.changeProvisionalFilterCondition({
        filterName: "projects",
        conditionName: conditionName,
        value: valuesArray,
        valueTitle: valuesArray.join(", "),
      });
    },
    //for saved views (?)
    filterByConditions(conditions) {
      conditions.forEach((condition) => {
        switch (condition.name) {
          case "search":
            this.search = condition.value;
            break;
          case "brokerId":
            if (condition.value) {
              if (!condition.value.type || (condition.value.type && !condition.value.value)) {
                condition.value = null;
              }
            } else {
              condition.value = null;
            }
            this.chosenUsers = condition.value
            break;
          case "dealTypes":
            this.dealTypesChosen = condition.value;
            this.dealTypes.forEach((type) => {
              if (this.dealTypesChosen.includes(type.value)) {
                type.selected = true;
              } else {
                type.selected = false;
              }
            });
            break;
          case "estateTypes":
            this.estateTypesChosen = condition.value;
            this.estateTypes.forEach((type) => {
              if (this.estateTypesChosen.includes(type.value)) {
                type.selected = true;
              } else {
                type.selected = false;
              }
            });
            // this.setExtras(this.estateTypesChosen);
            break;
          case "extraTypes":
            this.estateTypesExtrasChosen = condition.value;
            this.estateTypesExtras.forEach((type) => {
              if (this.estateTypesExtrasChosen.includes(type.value)) {
                type.selected = true;
              } else {
                type.selected = false;
              }
            });
            break;
          case "createdFromDate":
            this.createdFromDate = condition.value;
            break;
          case "createdToDate":
            this.createdToDate = condition.value;
        }
      });
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-con {
  display: flex;
  gap: 24px;
  padding-left: 104px;
}


.up {
  display: initial;
}


.down {
  display: initial;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}


.view-button {
  filter: brightness(0);
  width: 16px;
  height: 16px;
}

.button-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
}

.v-btn {
  margin-left: 12px !important;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
}

.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}

.left-row {
  justify-content: flex-start;

  .v-select {
    max-width: 250px;
  }

  .sector-type {
    margin-right: 42px;
  }
}

@media screen and (max-width: 1312px) {
  .toolbar-row {
    flex-wrap: nowrap;
  }
}

.right-row {
  justify-content: flex-end;

  .autocomplete {
    max-width: 300px;
  }

  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}

.project-radio-view {
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}

.project-radio-view-item {
  padding: 8px;
  cursor: pointer;
}

.icon-button-active {
  background-color: #f4f5f7;
}

.title-row {
  height: 32px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.view-section {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-size: 32px;
  line-height: 32px;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}

.tab {
  padding-bottom: 24px;
  cursor: pointer;
}

.tab-selected {
  border-bottom: 2px solid black;
}
</style>
