<template>
  <div class="content">
    <div class="content-loading"
         v-if="!projectsLoaded"
    >
      <div class="loader"/>
    </div>
    <div class="no-results-wrapper"
         v-else-if="Object.values(pipelinesWithUsableDeals).every(x => x.deals.length === 0)"
    >
      <p class="heading-geo32 no-results">
        {{ $t("NoResultsFound") }}
      </p>
      <p class="clear-filters-button" @click="removeFiltersProjects">
        {{ $t("ClearAllFilters") }}
      </p>
    </div>
    <div v-else>
      <GridContent
          v-if="activeProjectsView === 'GridView' && activeProjectsTab !== 'archives'"
          :pipelinesWithDeals="pipelinesWithUsableDeals"
          :taskUpdater="taskUpdater"
          @getProjectsForPipeline="getProjectsForPipeline"
          @reloadKanban="(value) => $emit('reloadKanban', value, true)"
          @updatePipeline="updatePipeline"
          @dragEnd="previousTo = ''"
          @cardClick="cardClick"
      />
      <ListContent
          v-if="activeProjectsView === 'ListView' || activeProjectsTab === 'archives'"
          :pipelinesWithDeals="pipelinesWithUsableDeals"
          :taskUpdater="taskUpdater"
          @getProjectsForPipeline="getProjectsForPipeline"
          @reloadKanban="(value) => $emit('reloadKanban', value, true)"
          @updatePipeline="updatePipeline"
          @cardClick="cardClick"
      />
    </div>

    <AmlWarningModal
        v-if="openAmlPanel"
        @canceled="getBack"
        @approved="openProject('openAml')"
        @closePanel="openAmlPanel = false"
    />
    <ConfirmationModal
        v-if="endProjectConfirmationModalOpen"
        @onConfirm="openProject('openProjectEnd')"
        @onCancel="endProjectConfirmationModalOpen = false"
    >
      {{ $t("ConfirmProjectComplete") }}
    </ConfirmationModal>
  </div>
</template>

<script>
import ListContent from "@/components/Deals/ListContent";
import GridContent from "@/components/Deals/GridContent";
import AmlWarningModal from "@/components/common/AmlWarningModal";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DealsContent",
  components: {
    ConfirmationModal,
    ListContent,
    GridContent,
    AmlWarningModal,
  },
  props: ["pipelinesWithUsableDeals", "projectSettings"],
  data() {
    return {
      arranged: false,
      originalArr: null,
      usableDeals: null,
      previousTo: "",
      search: "",
      dealTypes: [],
      lastMovedProject: null,
      pipelinesWithDeals: null,
      taskUpdater: 0,
      openAmlPanel: false,
      endProjectConfirmationModalOpen: false,
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "projectsLoaded",
      "filters",
      "activeProjectsView",
      "activeProjectsTab",
    ]),
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDeal",
      "removeFiltersProjects",
      "changeProvisionalFilterCondition",
    ]),
    getProjectsForPipeline(pipeline) {
      this.$emit('getProjectsForPipeline', pipeline)
    },
    getBack() {
      this.openAmlPanel = false;
    },
    openProject(state) {
      const routeParams = {id: this.lastMovedProject._id};
      switch (state) {
        case "openAml":
          routeParams.openAmlPanel = true;
          break;
        case "openProjectEnd":
          routeParams.openProjectEndPanel = true;
          break;
      }

      this.$router.push({
        name: "DealDetail",
        params: routeParams,
      });
    },
    async updatePipeline(evt) {
      let changedDeal = evt.draggedContext.element;
      this.lastMovedProject = changedDeal;
      const hasActiveAml = changedDeal.aml ? changedDeal.aml.some(aml => aml.activeBusiness) : false
      const [, to, , from] = [...evt.to.id.split('-'), ...evt.from.id.split('-')];
      const amlRequiredDealTypes = ["review", "Finished", "Done"]
      if (changedDeal.dealType === "Sale" && !hasActiveAml && amlRequiredDealTypes.includes(to)) {
        this.openAmlPanel = true;
        this.taskUpdater++;
        return;
      }
      if (to === 'Done' && from !== 'Done') {
        this.endProjectConfirmationModalOpen = true;
        this.taskUpdater++;
        return;
      }
      changedDeal.pipelineStep = to;
      // handle brokerageFee and count updates
      const previousTo = this.previousTo;
      this.previousTo = to;
      const formattedBrokerageFee = this.formatValue(changedDeal.brokerageFee) || 0;
      const targetPipeline = this.pipelinesWithUsableDeals[to];
      const sourcePipeline = this.pipelinesWithUsableDeals[previousTo || from];
      if (from === 'Done' && !(changedDeal.endState && changedDeal.endState.length > 0 && changedDeal.endState[changedDeal.endState.length - 1].projectEndReason === 'Won')) {
        if (from !== to) {
          this.updateBrokerageFee(targetPipeline, formattedBrokerageFee);
        }
        if (previousTo && previousTo !== 'Done') {
          this.updateBrokerageFee(sourcePipeline, -formattedBrokerageFee);
        }
      } else {
        this.updateBrokerageFee(targetPipeline, formattedBrokerageFee);
        this.updateBrokerageFee(sourcePipeline, -formattedBrokerageFee);
      }
      this.pipelinesWithUsableDeals[to].count += 1;
      this.pipelinesWithUsableDeals[previousTo || from].count -= 1;

      await axios.patch("/api/project", changedDeal);
    },
    cardClick(dealId, event) {
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        window.open(`${document.URL}/${dealId}/general`, "_blank");
      } else {
        this.changeSidepanelStateDeal();
        this.$router.push({
          name: "DealDetail",
          params: {id: dealId},
        });
      }
    },
    formatValue(inputValue) {
      if (inputValue === null || isNaN(inputValue)) {
        return null;
      }
      const decimalPlaces = 2;
      const factor = Math.pow(10, decimalPlaces);
      return Number(Math.round(inputValue * factor) / factor);
    },
    updateBrokerageFee(pipeline, value) {
      pipeline.brokerageFee += value;
      pipeline.brokerageFee = this.formatValue(pipeline.brokerageFee);
    }
  },
};
</script>

<style scoped lang="scss">
.content {
  margin-top: 12px;
}

.no-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 48px 0;
  flex-grow: 0;
  margin: 24px 112px;
}

.content-loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 60em;
  }
}
</style>
