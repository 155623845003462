var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "deal-grid-card-content",
    class: _vm.cardBorderColor,
    staticStyle: {
      "display": "inherit",
      "text-decoration": "unset",
      "position": "relative"
    },
    attrs: {
      "href": _vm.path + '/' + _vm.deal._id + '/general'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.deal._id, $event);
      }
    }
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "margin-bottom": "4px",
      "justify-content": "space-between"
    }
  }, [_c('v-row', {
    class: {
      'green-color': _vm.deal.dealType === 'Sale',
      'dark-blue-color': _vm.deal.dealType === 'Purchase',
      'pink-color': _vm.deal.dealType === 'LeaseOut',
      'orange-color': _vm.deal.dealType === 'AquireLease',
      'brown-color': _vm.deal.dealType === 'Referral'
    },
    staticStyle: {
      "max-width": "24px",
      "height": "4px",
      "border-radius": "100px",
      "margin-top": "2px"
    }
  }), _c('div', {
    staticClass: "price-section",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_vm.deal.brokerageFee && _vm.user.preferences && _vm.user.preferences.projects.brokerageFee.enabled ? _c('span', {
    class: {
      'deal-amount-con content-small': _vm.deal.pipelineStep !== 'Done',
      'deal-amount-con content-small green-color white-text': _vm.deal.pipelineStep === 'Done' && _vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Won',
      'deal-amount-con content-small red-color white-text-line-through': _vm.deal.pipelineStep === 'Done' && (_vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Lost' || _vm.deal.endState[_vm.deal.endState.length - 1].projectEndReason === 'Cancelled')
    },
    staticStyle: {
      "height": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount(_vm.deal.brokerageFee)) + " ")]) : _vm._e(), _vm.deal.amount && _vm.user.preferences && _vm.user.preferences.projects.projectValue.enabled ? _c('span', {
    staticClass: "deal-amount-con content-small",
    staticStyle: {
      "color": "#75787a",
      "height": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount(_vm.deal.amount)) + " ")]) : _vm._e()])], 1), _vm.user.preferences && _vm.user.preferences.projects.projectName.enabled && !_vm.user.preferences.projects.projectName.shortAddress.enabled ? _c('v-row', {
    staticClass: "content-semibold",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.name) + " ")]) : _vm._e(), _vm.user.preferences && _vm.user.preferences.projects.projectName.enabled && _vm.user.preferences.projects.projectName.shortAddress.enabled ? _c('v-row', {
    staticClass: "content-semibold",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.name.split(",")[0]) + " ")]) : _vm._e(), _vm.user.preferences && _vm.user.preferences.projects.clientName.enabled ? _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "margin-top": "4px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.contactPerson.contactName) + " ")]) : _vm._e(), _vm.user.preferences && _vm.user.preferences.projects.additionalInformation.enabled || _vm.deal.pipelineStep === 'Done' ? _c('v-row', {
    class: _vm.cardSeparatorColor,
    staticStyle: {
      "margin-bottom": "8px",
      "margin-top": "8px"
    }
  }) : _vm._e(), _vm.deal.pipelineStep !== 'Done' && _vm.user.preferences && _vm.user.preferences.projects.additionalInformation.enabled ? _c('v-row', {
    staticClass: "deal-tasks-files-con",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_vm.deal.openHouse ? _c('OpenHouseContainer', {
    attrs: {
      "task": _vm.deal.openHouse,
      "variant": 'small'
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('span', {
    staticClass: "tasks-con",
    staticStyle: {
      "background": "#f4f5f7",
      "border-radius": "1006px",
      "padding": "2px"
    }
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../assets/images/calendar.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(this.unfinishedEventsCounter) + " ")])]), _c('span', {
    staticClass: "tasks-con",
    staticStyle: {
      "background": "#f4f5f7",
      "border-radius": "1006px",
      "padding": "2px"
    }
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../assets/images/task.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(this.unfinishedCounter) + " ")])]), _c('span', {
    staticClass: "tasks-con",
    staticStyle: {
      "background": "#f4f5f7",
      "border-radius": "1006px",
      "padding": "2px"
    }
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../assets/images/sticky-note.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.deal.notes.filter(function (note) {
    return note.statusCode !== 0;
  }).length) + " ")])]), _c('span', {
    staticClass: "files-con",
    staticStyle: {
      "background": "#f4f5f7",
      "border-radius": "1006px",
      "padding": "2px"
    }
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../assets/images/attach.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.deal.files.length) + " ")])])])], 1) : _vm._e(), _vm.deal.pipelineStep === 'Done' ? _c('v-row', {
    staticClass: "deal-completed-con"
  }, [_c('span', {
    staticClass: "content-small-semibold",
    class: _vm.dealWon ? 'deal-won' : 'deal-lost'
  }, [_vm._v(" " + _vm._s(_vm.projectEndReason) + " ")]), _c('span', {
    staticClass: "content-small-semibold",
    class: _vm.dealWon ? 'deal-won' : 'deal-lost'
  }, [_vm._v(" " + _vm._s(_vm.deal.doneDate) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "open-tab-button-container"
  }, [_c('div', {
    staticClass: "open-new-tab-button",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.openProjectNewTab(_vm.deal);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/open_new_tab.svg"),
      "alt": ""
    }
  })])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }