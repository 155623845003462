<template>
  <div id="projects-kanban-content" class="deal-grid-content">
    <div
        class="deal-grid-container"
        v-for="(value, key) in filteredPipelinesWithDeals" :key="key"
    >
      <div class="deal-pipeline">
        <div>
          <div class="pipeline-name-count-row"
               style="display:flex; justify-content: space-between">
            <div style="align-items:center; padding-right: 8px;display:flex"
            >
              <h6>
                {{ $t(`ProjectPipeline.${key}`) }}
              </h6>
              <div style="margin-left: 4px">
                <h6 class="pipeline-count">({{ value.count }})</h6>
              </div>

              <!--  <img v-if="pipeline.name !== 'DoneDone'" src="../../assets/images/arrowNext.svg" alt=""/> -->

            </div>
            <BaseButton
                v-if="key === 'Done'"
                @onConfirm="archiveDeals(value.deals)"
                :display-key="'name'"
                :display-value="'value'"
                :items="DoneDonePipelineDropdown"
                :size="'icon'"
                :icon-left="'archive_button24_24.svg'"
                style="max-height: 16px;"
            />
          </div>
          <v-row class="pipeline-sub-info" style="height: 16px; margin-top:4px">
            <h6 v-if="value.brokerageFee" v-bind:class="{'orange-color': key !== 'Done', 'green-color': key === 'Done'}">
              {{ $nMapper.price(value.brokerageFee.toFixed(0), "€") }}</h6>
          </v-row>
        </div>
        <draggable
            :key="taskUpdater"
            :list="value.deals"
            :animation="200"
            ghost-class="ghost-card"
            drag-class="drag-card"
            group="tasks"
            @end="() => $emit('dragEnd')"
            :move="(val) => $emit('updatePipeline', val)"
            :id="'drag-' + key"
            class="scrollable"
        >
          <GridCard
              v-for="deal of value.deals"
              :key="deal._id"
              :deal="deal"
              :id="deal.pipelineStep"
              @cardClick="(dealId, event) => $emit('cardClick', dealId, event)"
              style="margin-top: 4px"
          />
          <div style="width: 100%; height: 120px; display: flex; justify-content: center; align-items: center"
               v-if="value.loading">
            <div class="loader"></div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable-rp";
import GridCard from "./GridCard.vue";
import {mapActions} from "vuex";
import BaseButton from "@/components/common/BaseButton";
import axios from "axios";

export default {
  name: "GridContent",
  components: {
    BaseButton,
    GridCard,
    draggable,
  },
  props: ["pipelinesWithDeals", "taskUpdater"],
  mounted() {
    this.initScrollBehaviour();
  },
  computed: {
    DoneDonePipelineDropdown() {
      const count = this.pipelinesWithDeals['Done'].deals.length;
      return [
        {
          name: this.$t("ArchiveCompleted"),
          value: "archive_completed",
          statusConfirmationModal: true,
          statusConfirmationModalText: `${this.$t("ArchiveVerb")} ${count} ${count > 1 || count === 0 ? this.$t("DealPlural") : this.$t('Deal').toLowerCase()}`,
        },
      ]
    },
    filteredPipelinesWithDeals() {
      return Object.entries(this.pipelinesWithDeals)
          .filter(([key]) => key !== 'archived')
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
    }
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeals", "changeSidepanelStateDeal"]),
    initScrollBehaviour() {
      for (const pipeline of Object.keys(this.pipelinesWithDeals)) {
        if (pipeline === 'archived') continue;
        document.getElementById('drag-' + pipeline).addEventListener('scroll', (e) => this.getMoreProjects(e, pipeline))
      }
    },
    getMoreProjects(e, pipeline) {
      if (this.pipelinesWithDeals[pipeline].loading) return;
      const element = e.target;
      if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 3) {
        if (!this.pipelinesWithDeals[pipeline].allLoaded) {
          this.pipelinesWithDeals[pipeline].loading = true;
          this.$emit('getProjectsForPipeline', pipeline)
        }
      }
    },
    archiveDeals(deals) {
      const promises = [];
      deals.forEach((deal) => {
        deal.pipelineStep = "archived"
        promises.push(axios.patch("/api/project", deal));
      })
      Promise.allSettled(promises).then((values) => {
        let successCounter = 0;
        values.forEach(response => {
          if (response.value.status === 200) {
            successCounter++;
          }
        })
        if (successCounter === values.length) {
          console.log("Every patch request successful")
        } else {
          console.log("Failed to archive " + values.length - successCounter + "project(s)")
        }

        this.toastSuccess(
            this.$t("Projects") + " " + this.$t("Archived").toLowerCase()
        );
        this.$emit("reloadKanban", "Done")
      })
    },
  },
}


</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-grid-content {
  display: flex;
  background-color: #f4f5f7;
  height: 80vh;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 8px;
  padding: 24px 48px 24px 112px;
  min-width: 1206px; //1366
  //max-width: 1694px;//1854
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.deal-pipeline {
  //width: 287px;
  /*  min-width:208px;
   max-width:287px; */
  height: 90%;
}

.pipeline-sub-info {
  color: #939597;
  gap: 8px;
  margin-top: 2px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #939597;
  border-radius: 50%;
  align-self: center;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  min-height: 100%;
  margin-top: 12px;
}

.orange-color {
  color: #FF5C01 !important
}

.pipeline-count {
  color: $grey950 !important;
}

.margin-param {
  margin-bottom: 32px !important;
}

.green-color {
  color: #0AAF60 !important;
}

@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 40em;
  }
}

.deal-grid-container {
  display: contents;
  flex: 1;
  min-width: 208px;
}

/* @media screen and (max-width: 1440px) {
  .deal-pipeline {
    width: 208px;
  }
} */

</style>
