<template>
  <div
    class="filters-wrapper"
    id="filters-wrapper"
    :key="'filters-updater-' + filtersUpdater"
  >
    <div class="advanced-filters-container">
      <div class="regular-filters">
        <div class="filters-section">
          <div class="filter-container">
            <label class="label12">{{ $t("ProjectCreated") }}</label>
            <div class="filter-inputs-container">
              <DropdownMulti
                :items="months"
                v-model="selectedMonths"
                :value-key="'value'"
                :display-key="'name'"
                :height="'34px'"
                :width="'120px'"
                :font-size="'12px'"
                :default-value="$t('AllProjects')"
              />

              <v-row v-if="projectCreatedCustomSelected" style="gap: 8px">
                <BaseDatePicker
                  :selected-from-date="filters.createdFrom"
                  :fromDate="true"
                  @dateFromChosen="filterByCreatedFromDate"
                />

                <label class="label12"> - </label>
                <BaseDatePicker
                  :selected-to-date="filters.createdTo"
                  :toDate="true"
                  @dateToChosen="filterByCreatedToDate"
                />
              </v-row>
            </div>
          </div>
        </div>

        <div class="filter-container">
          <label class="label12">{{ $t("Location") }}</label>
          <div class="filter-inputs-container">
            <Dropdown
              v-if="counties"
              :items="counties"
              :display-key="'name'"
              :value-key="'name'"
              :default-item="counties[0]"
              :search-box="true"
              :selected="
                counties.find((county) => county.name === filters.county)
              "
              @itemSelected="filterByCounty"
            />
            <Dropdown
              :disabled="citiesDisabled"
              :key="'cities-' + citiesUpdater"
              :items="cities"
              :display-key="'name'"
              :value-key="'name'"
              :default-item="cities[0]"
              :search-box="true"
              @itemSelected="filterByCity"
            />
            <DropdownMulti
              :disabled="cityPartsDisabled"
              :items="cityParts"
              :display-key="'name'"
              :value-key="'name'"
              :height="'34px'"
              :font-size="'12px'"
              :default-value="$t('CityPart')"
              :enable-search="true"
              v-model="selectedCityParts"
            />
          </div>
        </div>
      </div>
      <div class="purchase-filters">
        <p class="purchase-filters-heading">
          <span class="purchase">{{ $t("Purchase") }}</span> <span>|</span>
          <span class="rent">{{ $t("AquireLease") }}</span>
        </p>
        <div class="input-filters">
          <div class="filter-container">
            <label class="label12">{{ $t("Price") }} (€)</label>
            <div class="filter-inputs-container">
              <InputSmall
                @change="setPriceMin"
                :placeholder="'Min'"
                :width="'80px'"
                :type="'number'"
                v-model="filters.priceMin"
              ></InputSmall>
              <InputSmall
                @change="setPriceMax"
                :placeholder="'Max'"
                :width="'80px'"
                :type="'number'"
                v-model="filters.priceMax"
              ></InputSmall>
            </div>
          </div>
          <div class="filter-container">
            <label class="label12">{{ $t("PricePerSqmEur") }}</label>
            <div class="filter-inputs-container">
              <InputSmall
                @change="setPriceSqmMin"
                :placeholder="'Min'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.priceMinSqm"
              ></InputSmall>
              <InputSmall
                @change="setPriceSqmMax"
                :placeholder="'Max'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.priceMaxSqm"
              ></InputSmall>
            </div>
          </div>

          <div class="filter-container">
            <label class="label12">{{ $t("Floor") }}</label>
            <div class="filter-inputs-container">
              <InputSmall
                @change="setFloorMin"
                :placeholder="'Min'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.floorMin"
              ></InputSmall>
              <InputSmall
                @change="setFloorMax"
                :placeholder="'Max'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.floorMax"
              ></InputSmall>
            </div>
          </div>

          <div class="filter-container">
            <label class="label12">{{ $t("AreaOf") }} (M&#178;)</label>
            <div class="filter-inputs-container">
              <InputSmall
                @change="setAreaMin"
                :placeholder="'Min'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.areaMin"
              ></InputSmall>
              <InputSmall
                @change="setAreaMax"
                :placeholder="'Max'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.areaMax"
              ></InputSmall>
            </div>
          </div>

          <div class="filter-container">
            <label class="label12">{{ $t("Rooms") }}</label>
            <div class="filter-inputs-container">
              <InputSmall
                @change="setRoomMin"
                :placeholder="'Min'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.roomMin"
              ></InputSmall>
              <InputSmall
                @change="setRoomMax"
                :placeholder="'Max'"
                :type="'number'"
                :width="'80px'"
                v-model="filters.roomMax"
              ></InputSmall>
            </div>
          </div>
        </div>

        <div class="filter-container" :key="toggleUpdater">
          <label class="label12">{{ $t("Extras") }}</label>
          <div class="filter-inputs-container" style="gap: 24px">
            <div
              class="toggle-wrapper"
              @click="
                filters.balcony = !filters.balcony;
                setBalcony();
              "
            >
              <img
                class="toggle-img"
                :src="
                  filters.balcony
                    ? require('../../assets/images/toggle-active.svg')
                    : require('../../assets/images/toggle-inactive.svg')
                "
                alt=""
              />
              <p>{{ $t("Balcony") }}</p>
            </div>
            <div
              class="toggle-wrapper"
              @click="
                filters.parkingOption = !filters.parkingOption;
                setParkingOption();
              "
            >
              <img
                class="toggle-img"
                v-if="filters.parkingOption"
                src="../../assets/images/toggle-active.svg"
                alt=""
              />
              <img
                class="toggle-img"
                v-if="!filters.parkingOption"
                src="../../assets/images/toggle-inactive.svg"
                alt=""
              />
              <p>{{ $t("Parking") }}</p>
            </div>
            <div
              class="toggle-wrapper"
              @click="
                filters.roomsSeparate = !filters.roomsSeparate;
                setRoomsSeparate();
              "
            >
              <img
                class="toggle-img"
                v-if="filters.roomsSeparate"
                src="../../assets/images/toggle-active.svg"
                alt=""
              />
              <img
                class="toggle-img"
                v-if="!filters.roomsSeparate"
                src="../../assets/images/toggle-inactive.svg"
                alt=""
              />
              <p>{{ $t("RoomsSeparate") }}</p>
            </div>
            <div class="toggle-wrapper" @click="setStoreRoom()">
              <img
                class="toggle-img"
                v-if="filters.storeRoom"
                src="../../assets/images/toggle-active.svg"
                alt=""
              />
              <img
                class="toggle-img"
                v-if="!filters.storeRoom"
                src="../../assets/images/toggle-inactive.svg"
                alt=""
              />
              <p>{{ $t("Storeroom") }}</p>
            </div>
            <div
              class="toggle-wrapper"
              :key="toggleUpdater"
              @click="setSauna()"
            >
              <img
                class="toggle-img"
                v-if="filters.sauna"
                src="../../assets/images/toggle-active.svg"
                alt=""
              />
              <img
                class="toggle-img"
                v-if="!filters.sauna"
                src="../../assets/images/toggle-inactive.svg"
                alt=""
              />
              <p>{{ $t("Sauna") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-footer">
      <p style="cursor: pointer" @click="clearFilters" class="link-small">
        {{ $t("ResetAllFilters") }}
      </p>
    </div>
  </div>
</template>

<script>
import InputSmall from "@/components/common/InputSmall";
import Dropdown from "@/components/common/Dropdown";
import DropdownMulti from "@/components/common/DropdownMulti";
import { mapActions, mapGetters } from "vuex";
import nanoid from "nanoid";
import moment from "moment";
import BaseDatePicker from "@/components/common/BaseDatePicker";
import _ from "lodash";
//import StatusDropdown from "@/components/Listings/StatusDropdown";

const locationsEstonia = require("@/components/common/locations_estonia2.js");
export default {
  name: "AdvancedFilters",
  components: {
    InputSmall,
    Dropdown,
    DropdownMulti,
    //StatusDropdown
    BaseDatePicker,
  },
  props: ["filterData"],
  data() {
    return {
      citiesDisabled: true,
      citiesRemoved: false,
      cityPartsDisabled: true,
      districtsDisabled: false,
      filters: null,
      filtersUpdater: 0,
      toggleUpdater: 0,
      citiesUpdater: 0,
      cityPartsUpdater: 0,
      conditions: [
        { name: this.$t("Select"), value: "select", _id: nanoid(16) },
        { name: this.$t("NewBuilding"), value: "ALACRITY_NEW_BUILDING" },
        {
          name: this.$t("NewlyDecorated"),
          value: "ALACRITY_TYPE_UUSVIIMISTLUS",
        },
        { name: this.$t("Renovated"), value: "ALACRITY_RENEWED" },
        { name: this.$t("EssentialRepairDone"), value: "ALACRITY_SAN" },
        { name: this.$t("Satisfactory"), value: "ALACRITY_KESKMINE" },
        { name: this.$t("NeedsCompleteRenewal"), value: "ALACRITY_TYPE_KAP" },
        { name: this.$t("NeedsDecoration"), value: "ALACRITY_TYPE_REM" },
      ],
      counties: null,
      timeout: null,
      cities: [{ name: this.$t("City"), value: "city", _id: nanoid(16) }],
      cityParts: [],
      selectedCityParts: [],
      selectedMonths: [],
      months: [
        { name: this.$t('January'), value: 'January' },
        { name: this.$t('February'), value: 'February' },
        { name: this.$t('March'), value: 'March' },
        { name: this.$t('April'), value: 'April' },
        { name: this.$t('May'), value: 'May' },
        { name: this.$t('June'), value: 'June' },
        { name: this.$t('July'), value: 'July' },
        { name: this.$t('August'), value: 'August' },
        { name: this.$t('September'), value: 'September' },
        { name: this.$t('October'), value: 'October' },
        { name: this.$t('November'), value: 'November' },
        { name: this.$t('December'), value: 'December' },
        { name: this.$t("Custom"), value: "custom" }
      ],
    };
  },
  created() {
    this.filters = _.cloneDeep(this.filterData);
    if (this.$route.query.notificationId) {
      this.filters.brokerId = null;
    } else {
      this.filters.brokerId = this.user._id;
    }

    let counties = [
      { name: this.$t("County"), value: "county", _id: nanoid(16) },
    ];
    locationsEstonia.locations.counties.forEach((location) => {
      counties.push({ name: location.NIMETUS, value: location.KOOD });
    });
    this.counties = counties;
  },
  mounted() {},
  beforeDestroy() {
    document.onkeyup = null;
  },
  computed: {
    ...mapGetters(["user", "provisionalFilters"]),
    projectCreatedCustomSelected() {
      return this.provisionalFilters.projects
        .find((condition) => condition.name === "months")
        .value.some((month) => month === "custom");
    },
    pastDayOptions() {
      return [
        { name: this.$t("AllDeals"), value: null },
        {
          name: this.$t("Today"),
          value: {
            start: moment().startOf("day"),
            end: moment().endOf("day"),
          },
        },
        {
          name: this.$t("Yesterday"),
          value: {
            start: moment().subtract(1, "days").startOf("day"),
            end: moment().subtract(1, "days").endOf("day"),
          },
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastDays"),
          value: {
            start: moment().subtract(3, "days").startOf("day"),
            end: moment().endOf("day"),
          },
        },
        {
          name: this.$t("InLast") + " 10 " + this.$t("InLastDays"),
          value: {
            start: moment().subtract(10, "days").startOf("day"),
            end: moment().endOf("day"),
          },
        },
        {
          name: this.$t("InLast") + " 30 " + this.$t("InLastDays"),
          value: {
            start: moment().subtract(30, "days").startOf("day"),
            end: moment().endOf("day"),
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["filter"]),
    setCities(county) {
      let citiesTemp = [
        { name: this.$t("City"), value: "city", _id: nanoid(16) },
      ];
      locationsEstonia.locations.cities.forEach((location) => {
        if (location.YLEMKOMP_KOOD === county.value) {
          let name = location.NIMETUS;
          let value = location.KOOD;
          citiesTemp.push({ name, value });
        }
      });
      this.cities = citiesTemp;
      this.cityParts = [];
      this.enableCities();
      this.enableCityParts();
    },
    setCityParts(city) {
      let cityPartsTemp = [];
      locationsEstonia.locations.districts.forEach((location) => {
        if (location.YLEMKOMP_KOOD === city.value) {
          let name = location.NIMETUS;
          let value = location.KOOD;
          cityPartsTemp.push({ name, value });
        }
      });
      this.cityParts = cityPartsTemp;
      this.cityPartsDisabled = this.cityParts.length === 0; 
    },
    async setBalcony() {
      this.toggleUpdater++;

      await this.filter({
        filterName: "projects",
        conditionName: "balcony",
        value: this.filters.balcony,
        valueTitle: this.filters.balcony ? "✓" : "",
      });
    },
    async setParkingOption() {
      this.toggleUpdater++;

      await this.filter({
        filterName: "projects",
        conditionName: "parkingOption",
        value: this.filters.parkingOption,
        valueTitle: this.filters.parkingOption ? "✓" : "",
      });
    },
    async setRoomsSeparate() {
      this.toggleUpdater++;

      await this.filter({
        filterName: "projects",
        conditionName: "roomsSeparate",
        value: this.filters.roomsSeparate,
        valueTitle: this.filters.roomsSeparate ? "✓" : "",
      });
    },
    async setStoreRoom() {
      this.filters.storeRoom = !this.filters.storeRoom;

      await this.filter({
        filterName: "projects",
        conditionName: "storeRoom",
        value: this.filters.storeRoom,
        valueTitle: this.filters.storeRoom ? "✓" : "",
      });
      // this.toggleUpdater++;
    },
    async setSauna() {
      this.filters.sauna = !this.filters.sauna;
      // this.toggleUpdater++;
      await this.filter({
        filterName: "projects",
        conditionName: "sauna",
        value: this.filters.sauna,
        valueTitle: this.filters.sauna ? "✓" : "",
      });
      this.toggleUpdater++;
    },
    async setPriceSqmMin() {
      await this.filter({
        filterName: "projects",
        conditionName: "priceMinSqm",
        value: this.filters.priceMinSqm,
        valueTitle: "Price",
      });
    },
    async setPriceSqmMax() {
      await this.filter({
        filterName: "projects",
        conditionName: "priceMaxSqm",
        value: this.filters.priceMaxSqm,
        valueTitle: this.filters.priceMaxSqm,
      });
    },
    async setPriceMin() {
      await this.filter({
        filterName: "projects",
        conditionName: "priceMin",
        value: this.filters.priceMin,
        valueTitle: "Price",
      });
    },
    async setPriceMax() {
      await this.filter({
        filterName: "projects",
        conditionName: "priceMax",
        value: this.filters.priceMax,
        valueTitle: this.filters.priceMax,
      });
    },
    async setFloorMin() {
      await this.filter({
        filterName: "projects",
        conditionName: "floorMin",
        value: this.filters.floorMin,
        valueTitle: this.filters.floorMin,
      });
    },
    async setFloorMax() {
      await this.filter({
        filterName: "projects",
        conditionName: "floorMax",
        value: this.filters.floorMax,
        valueTitle: false,
      });
    },
    async setAreaMin() {
      await this.filter({
        filterName: "projects",
        conditionName: "areaMin",
        value: this.filters.areaMin,
        valueTitle: "AreaMin",
      });
    },
    async setAreaMax() {
      await this.filter({
        filterName: "projects",
        conditionName: "areaMax",
        value: this.filters.areaMax,
        valueTitle: "AreaMax",
      });
    },
    async setRoomMin() {
      await this.filter({
        filterName: "projects",
        conditionName: "roomMin",
        value: this.filters.roomMin,
        valueTitle: this.filters.roomMin,
      });
    },
    async setRoomMax() {
      await this.filter({
        filterName: "projects",
        conditionName: "roomMax",
        value: this.filters.roomMax,
        valueTitle: this.filters.roomMax,
      });
    },
    async filterByCounty(county) {
      this.setCities(county);
      // console.log("county", county)
      await this.filter({
        filterName: "projects",
        conditionName: "county",
        value: county.value === "county" ? null : county.name,
        valueTitle: county.name,
      });
      /*  this.enableCities()
            this.citiesUpdater++;
 */
    },
    async filterByCity(city) {
      this.setCityParts(city);
      await this.filter({
        filterName: "projects",
        conditionName: "city",
        value: city.value === "city" ? null : city.name,
        valueTitle: city.name,
      });
    },
    async filterByCityPart(newCityParts) {
      await this.filter({
        filterName: "projects",
        conditionName: "cityParts",
        value: newCityParts.map((cityPart) => cityPart.name),
        valueTitle: newCityParts.map((cityPart) => cityPart.name),
      });
    },
    async filterByConditions(newConditions) {
      await this.filter({
        filterName: "projects",
        conditionName: "conditions",
        value: newConditions.map((condition) => condition.value),
        valueTitle: newConditions.map((condition) => condition.name),
      });
    },
    async filterByLastUpdated(newTimeFrame) {
      await this.filter({
        filterName: "projects",
        conditionName: "lastUpdated",
        value: newTimeFrame.value,
        valueTitle: newTimeFrame.name,
      });
    },
    async filterByFirstPublished(newTimeFrame) {
      await this.filter({
        filterName: "projects",
        conditionName: "firstPublished",
        value: newTimeFrame.value,
        valueTitle: newTimeFrame.name,
      });
    },
    async filterByMonths(newMonths) {
      await this.filter({
        filterName: "projects",
        conditionName: "months",
        value: newMonths.map((month) => month.value),
        valueTitle: newMonths.map((month) => month.value),
      });
    },
    async filterByCreatedFromDate(fromDate) {
      await this.filter({
        filterName: "projects",
        conditionName: "createdFrom",
        value: fromDate,
        valueTitle: fromDate,
      });
    },
    async filterByCreatedToDate(toDate) {
      await this.filter({
        filterName: "projects",
        conditionName: "createdTo",
        value: toDate,
        valueTitle: toDate,
      });
    },
    enableCities() {
      this.citiesDisabled = false;
      this.citiesUpdater++;
    },
    disableCities() {
      this.citiesDisabled = true;
    },
    // enableCityParts() {
    //   this.cityPartsDisabled = false;
    //   this.cityPartsUpdater++;
    // },
    // disableCityParts() {
    //   this.cityPartsDisabled = true;
    // },
    enabledistricts() {
      this.districtsDisabled = false;
    },
    disabledistricts() {
      this.districtsDisabled = true;
    },
    async clearFilters() {
      this.$emit("filtersReset");
    },
  },
  watch: {
    selectedMonths: {
      handler(newVal) {
        let monthsArray = [];
        newVal.forEach((month) => {
          monthsArray.push(month.value);
        });
        this.filter({
          filterName: "projects",
          conditionName: "months",
          value: monthsArray,
          valueTitle: monthsArray.join(", "),
        });
      },
      deep: true,
    },
    selectedCityParts: {
      handler(newVal) {
        let cityPartsArray = [];
        newVal.forEach((cityPart) => {
          cityPartsArray.push(cityPart.name);
        });
        this.filter({
          filterName: "projects",
          conditionName: "cityParts",
          value: cityPartsArray,
          valueTitle: cityPartsArray.join(", "),
        });
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss" scoped>
.filters-wrapper {
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  padding: 32px 64px 8px 0;
}

.filter-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 48px;
}

.col {
  flex-wrap: nowrap;
}

.row + .row {
  margin-top: 8px;
}

.filter-row + .filter-row {
  margin-top: 24px;
}

.inputs-wrapper {
  gap: 8px;
}

.select-inputs-wrapper {
  gap: 24px;
}

.toggle-wrapper {
  display: flex;
  height: 32px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    /* black */

    color: #000000;
  }
}

.advanced-filters-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px 24px;
}

.filters-section {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-inputs-container {
  display: flex;
  gap: 8px;
}
.regular-filters {
  display: flex;
  gap: 16px;
  flex-direction: row;
}
.purchase-filters {
  position: relative;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 16px 12px 12px 12px;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 4px;
    border-radius: 6px;
    background: linear-gradient(to right, #4c8dd9 5%, #ff5c01 50%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
.purchase-filters-heading {
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  left: 24px;
  top: -8px;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: var(--white, #fff);
}
.input-filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.filter-footer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
}
.purchase {
  color: #4c8dd9;
}
.rent {
  color: #ff5c01;
}
</style>
