<template>
  <v-col style="margin: 0; padding: 0" v-if="tasksLoaded">
    <h6
      v-if="title"
      class="sub-heading"
      style="padding-left: 0px; padding-bottom: 0px"
    >
      {{ $t("Tasks") }}
      <button
        @click="isOpen = !isOpen"
        type="button"
        style="margin-left: 16px"
        class="add-tasks"
      >
        <img src="../../assets/images/plus.svg" alt="" />

        <span class="add-task-inside">{{ $t("AddTask.Title") }}</span>
      </button>
    </h6>

    <div class="customer-row">
      <v-row
        style="margin: 0 !important; padding: 0 !important"
        justify="space-between"
        v-for="task in tasks"
        :key="task._id"
        class="task-rows"
      >
        <div
          class="task-row"
          style="
            display: flex;
            min-width: 100%;
            justify-content: space-between;
            align-items: center;
          "
          v-if="task.statusCode != 0"
        >
          <div
            class="logo-and-text"
            style="display: flex; gap: 8px; width: 60%; align-items: center"
          >
            <label class="container">
              <input
                type="checkbox"
                class="checkbox"
                name="peeter"
                :checked="task.isCompleted"
                @change="setCompletedStatus($event, task)" />
              <span class="checkmark"></span
            ></label>
            <p
              @click="openField(task)"
              v-if="task.isDisabled === true"
              v-bind:class="{
                'task-text': task.isCompleted === false,
                'task-text gray-text': task.isCompleted === true,
              }"
              style="margin-bottom: 0 !important"
            >
              {{ task.content }}
            </p>
            <v-textarea
              auto-grow
              autofocus
              v-if="task.isDisabled === false"
              class="task-text text-area"
              @focusout="closeTask(task)"
              outlined
              row-height="10"
              rows="1"
              hide-details
              style="margin-bottom: 0 !important"
              v-model="task.content"
            >
              <template v-slot:end>
                <v-btn outlined> </v-btn>
              </template>
            </v-textarea>
          </div>
          <div class="info-text-wrapper">
            <p
              v-if="task.assignedTo"
              v-bind:class="{
                'createdAt black-text': task.isCompleted === false,
                createdAt: task.isCompleted === true,
              }"
            >
              {{ task.assignedTo.name }}
            </p>
            <p
              v-if="task.dates.endDate"
              v-bind:class="{
                'createdAt black-text': task.isCompleted === false,
                createdAt: task.isCompleted === true,
              }"
            >
              {{ formatDate(task.dates.endDate) }}
            </p>
          </div>
          <div class="action-buttons-wrapper">
            <v-menu
              ref="menu"
              class="no-active"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              max-height="250"
              nudge-left="70"
            >
              <template v-slot:activator="{ on: onMenu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <div
                      v-on="{ ...onMenu, ...onTooltip }"
                      class="action-button"
                    >
                      <img src="../../assets/images/user.svg" alt="" />
                    </div>
                  </template>
                  <span>{{ $t("ListView.Header.assignee") }}</span>
                </v-tooltip>
              </template>
              <v-row
                class="menu-item-person"
                v-for="userItem in users"
                :key="userItem._id"
                @click="addAssignee(task, userItem)"
              >
                <p class="userItem-name">{{ userItem.name }}</p>
              </v-row>
            </v-menu>
            <date-pick @input="addDeadlineDate($event, task)">
              <template v-slot:default="{ toggle }">
                <div class="action-button" @click="toggle">
                  <img src="../../assets/images/calendar.svg" alt="" />
                </div>
              </template>
            </date-pick>
          </div>
        </div>
      </v-row>

      <!--          NEW NOTE FIELD
    -->
      <v-row style="margin: 0; padding: 0" v-if="isOpen || isAccordionOpen">
        <div
          style="display: flex; gap: 8px; width: 100%; align-content: center"
        >
          <v-textarea
            @focusout="closeNewField()"
            autofocus
            auto-grow
            outlined
            hide-details
            row-height="10"
            :placeholder="$t('AddTask.Title') + '...'"
            textarea
            rows="1"
            class="task-text text-area"
            v-model="newTask.content"
            >{{ newTask }}</v-textarea
          >
        </div>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import DatePick from "vue-date-pick";
import { mapGetters, mapActions } from "vuex";
import "vue-date-pick/dist/vueDatePick.css";
import nanoid from "nanoid";
import axios from "axios";
import moment from "moment";
export default {
  name: "Tasks",
  props: [
    "title",
    "isAccordionOpen",
    "projectId",
    "projectName",
    "contactId",
    "contactName",
  ],
  components: {
    DatePick,
  },
  data: () => ({
    isOpen: false,
    deadlineDate: null,
    tasksLoaded: false,
    tasks: [],
    newTask: {
      _id: nanoid(20),
      createdAt: Date.now(),
      content: "",
      assignedTo: {
        userId: null,
        name: null,
      },
      isCompleted: false,
      type: "meeting",
      dates: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      },
      linkedProject: {
        id: null,
        name: null,
      },
      linkedContact: {
        id: null,
        name: null,
      },
      isPrivate: false,
      accessToken: null,
    },
  }),
  created() {
    this.getTasks();
    this.getUsers();
    this.newTask.assignedTo.userId = this.user._id;
    this.newTask.assignedTo.name = this.user.name;
  },
  mounted() {},
  computed: {
    ...mapGetters(["users", "user", "customers"]),
    unfinishedCounter() {
      let unfinishedCounter = 0;
      this.tasks.forEach((task) => {
        if (!task.isCompleted && task.content !== "") {
          unfinishedCounter++;
        }
      });
      return unfinishedCounter;
    },
  },
  /*Jälgib kui mingi muutuja computed-s/data-s muutub */
  watch: {
    unfinishedCounter(val) {
      this.$emit("unfinishedTasksCounter", val);
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    async getTasks() {
      let response = null;
      if (this.projectId) {
        response = await axios.get(`/api/tasks/project/${this.projectId}`);
      }
      if (this.contactId) {
        response = await axios.get(`/api/tasks/contact/${this.contactId}`);
      }
      this.tasks = response.data;
      this.tasks.sort((b, a) =>
        b.isCompleted > a.isCompleted
          ? 1
          : a.isCompleted > b.isCompleted
          ? -1
          : 0
      );
      this.tasks.forEach((e) => {
        e.isDisabled = true;
      });
      this.newTask.projectName = this.projectName;
      this.newTask.contactName = this.contactName;
      this.tasksLoaded = true;
    },
    openField(task) {
      this.$set(task, "isDisabled", false);
      this.$forceUpdate();
    },
    closeNewField() {
      this.addNewTask();
      this.isOpen = false;
      this.$emit("closeField");
    },
    async closeTask(task) {
      this.$set(task, "isDisabled", true);
      /*this.tasks.forEach((e) => {
        e.isDisabled = true;
      });*/
      await this.saveTask(task);
      this.$emit("closeField");
      this.$forceUpdate();
    },
    async addAssignee(task, user) {
      this.$set(task.assignedTo, "userId", user._id);
      this.$set(task.assignedTo, "name", user.name);
      await this.saveTask(task);
    },
    async addDeadlineDate(userDate, task) {
      this.$set(task.dates, "endDate", userDate);
      await this.saveTask(task);
    },
    async setCompletedStatus(event, task) {
      let isChecked = event.target.checked;
      if (isChecked) {
        this.$set(task, "isCompleted", true);
        await this.saveTask(task);
      } else if (!isChecked) {
        this.$set(task, "isCompleted", false);
        await this.saveTask(task);
      } else {
        console.log("mingi jama");
      }
    },

    async saveTask(task) {
      try {
        await axios.patch(`/api/task/${task._id}`, task);
        if (task.content === "") {
          this.tasks.forEach((task2, index) => {
            if (task2._id === task._id) {
              this.tasks.splice(index, 1);
              this.$forceUpdate();
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addNewTask() {
      try {
        if (!this.newTask || this.newTask.content === "") {
          return;
        }
        this.tasks.push(this.newTask);
        if (this.user.microsoft) {
          const access = await this.getMsalAccessToken();
          if (access) {
            this.newTask.accessToken = access.accessToken;
          }
        }
        if (this.projectId) {
          await axios.post(`/api/task/project/create/${this.projectId}`, {
            task: this.newTask,
          });
        } else if (this.contactId) {
          await axios.post(`/api/task/contact/create/${this.contactId}`, {
            task: this.newTask,
          });
        }

        this.newTask._id = nanoid(20);
        this.newTask.content = "";
        this.newTask.createdAt = Date.now();

        await this.getTasks();
      } catch (e) {
        console.log(e);
      }
    },

    async saveTasks() {
      await this.getTasks();
    },
    formatDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-row {
  border: 0px solid #e6e8ec !important;
  border-radius: 8px;
  padding-top: 12px;
}
.task-rows {
  //border-bottom: 1px solid #e6e8ec;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.task-row {
  flex-flow: row;
  cursor: pointer;
  max-width: 100%;
  min-height: 56px;
  padding-left: 12px;
  padding-right: 24px;
  &:hover {
    .createdAt {
      display: none;
    }
    .action-buttons-wrapper {
      display: flex;
    }
  }
}

.task-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
  font-size: 14px;
}
.text-area {
  max-height: 100% !important;
}
.task-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}
.createdAt {
  display: flex;
  font-size: 14px !important;
  line-height: 16px;
  align-items: center;
  margin-bottom: 0 !important;
  color: #939597;
  text-align: left !important;
  width: 47%;
}
.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}
.add-task {
  margin-top: 16px;
}
.add-tasks {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}
.userItem-name {
  font-size: 14px;
}
.add-task-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}
.action-button {
  border: 1px solid #e6e8ec;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
  &:hover {
    background-color: #e6e8ec;
  }
  img {
    width: 16px;
  }
}
.action-buttons-wrapper {
  display: none;
  flex-flow: row;
  gap: 16px;
}
.gray-text {
  color: #75787a;
  font-size: 14px !important;
}
.black-text {
  color: black;
  font-size: 14px !important;
}
.no-active {
  max-height: 200px;
  overflow: scroll;
}
.menu-item-person {
  cursor: pointer;
  background-color: white;
  margin-top: 0 !important;
  padding: 12px;
  &:hover {
    background-color: #e6e8ec;
  }
  p {
    font-size: 14px;
    margin-bottom: 0 !important;
  }
}

/* Hide the browser's default checkbox */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  width: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 0 !important;
}
.container input {
  position: absolute;

  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 8px;
  background-color: #eee;
  display: flex;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0aaf60;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: relative;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: flex;
  justify-content: center;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.info-text-wrapper {
  display: flex;
  width: 30%;
  flex-flow: row;
  gap: 12px;
  text-align: right;
  justify-content: flex-end;
}
</style>
